import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {
	Actions,
	BandeauInfo,
	Button,
	checkAuthorities,
	checkFichierPJExamenCandidatExists,
	DATE_FORMAT_MMDDYYYY_SLASH,
	DATE_FORMAT_YYYYMMDD_DASH,
	DateInput,
	DATETIME_FORMAT_SERVER_TIMESTAMP,
	declarerDossierNonConformeExamenCandidat,
	EmptyResult,
	Error,
	EXAMEN_CANDIDAT_STATUT_DOSSIER,
	formatDate,
	getListePiecesJustificativesExamenSelectionne,
	getPiecesJustificativesForExamenCandidat,
	injectProfil,
	InputDisabled,
	Loader,
	PieceJustificativeNameBadge,
	PROFIL_UTILISATEUR,
	refreshBapdInfos,
	refreshCandidatNoTelepilote,
	resetPiecesJustificativesForExamenCandidat,
	ResponsiveForm,
	togglePjExamenCandidatValidee,
	validerDossierExamenCandidat
} from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { FormSwitch, getStyles, Title } from 'isotope-client'
import React, { ChangeEvent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, FieldArray, FormErrors, reduxForm } from 'redux-form'
import PopupValidationDossierExamenCandidat from '../popup/PopupValidationDossierExamenCandidat'
import { ExamenCandidatPieceJustificative } from '../model/PiecesJustificativesExamenCandidatModel'
import { Candidat } from '../../../candidat/model/candidat'
import { InfoExamenCandidat } from '../../model/examenCandidat'
import PopupDeclarationNonConformite from '../popup/PopupDeclarationNonConformite'
import { download } from 'isotope-client/components/download/IsotopeLink'

const styles = (theme: any): React.CSSProperties => getStyles({
	row: {
		minHeight: 60,
		borderBottom: '1px solid rgba(224, 224, 224, 1)', // Diviseur entre les lignes du "tableau"
		paddingBottom: 12,
		marginBottom: 10
	},
	header: { // On est obligé de simuler les header d'une table
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: 13,
		paddingBottom: 15,
		paddingTop: 15
	},
	headerBox: {
		borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	checkbox: {
		marginTop: 5,
		marginBottom: -5,
		paddingLeft: 14
	},
	pjType: {
		color: theme.palette.primary.main,
		fontWeight: 500
	},
	emptyPj: {
		width: '100%'
	},
	pjBadgeList: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexWrap: 'wrap',
		columnGap: '12px',
		rowGap: '6px',
		marginTop: '10px'
	}
})

const actions = {
	getPiecesJustificativesForExamenCandidatAction: getPiecesJustificativesForExamenCandidat,
	resetPiecesJustificativesForExamenCandidatAction: resetPiecesJustificativesForExamenCandidat,
	togglePjExamenCandidatValideeAction: togglePjExamenCandidatValidee,
	declarerDossierNonConformeExamenCandidatAction: declarerDossierNonConformeExamenCandidat,
	validerDossierExamenCandidatAction: validerDossierExamenCandidat,
	refreshCandidatNoTelepiloteAction: refreshCandidatNoTelepilote,
	refreshBapdInfosAction: refreshBapdInfos
} as const

type ActionProps = {
	getPiecesJustificativesForExamenCandidatAction: (idCandidat: number,
	                                                 idExamenCandidat: number) => Promise<void>
	resetPiecesJustificativesForExamenCandidatAction: () => Promise<void>
	togglePjExamenCandidatValideeAction: (idCandidat: number,
	                                      idExamenCandidat: number,
	                                      idPj: number,
	                                      validee: boolean) => Promise<void>
	declarerDossierNonConformeExamenCandidatAction: (idCandidat: number,
	                                                 idExamenCandidat: number,
	                                                 form: any) => Promise<void>
	validerDossierExamenCandidatAction: (idCandidat: number,
	                                     idExamenCandidat: number) => Promise<void>
	refreshCandidatNoTelepiloteAction: (idCandidat: number) => Promise<void>,
	refreshBapdInfosAction: (idCandidat: number,
	                         idExamenCandidat: number) => Promise<void>
}

type OwnProps = {
	etatCivil: Candidat
	examenSelectionne: InfoExamenCandidat
	piecesJustificativesExamen: ExamenCandidatPieceJustificative[]
	intl: any
	classes: any
	snackError: any
	snackSuccess: any
	change: any
	submitting: boolean
	handleSubmit: any
}

type PiecesJustificativesExamenCandidatProps = OwnProps & ActionProps

const FORM_NAME = 'piecesJustificativesExamenForm'
const FIELD_NAME_PJ = 'piecesJustificativesExamen'

const PiecesJustificativesExamenCandidat = ({
	                                            etatCivil,
	                                            examenSelectionne,
	                                            piecesJustificativesExamen,
	                                            getPiecesJustificativesForExamenCandidatAction,
	                                            resetPiecesJustificativesForExamenCandidatAction,
	                                            togglePjExamenCandidatValideeAction,
	                                            declarerDossierNonConformeExamenCandidatAction,
	                                            validerDossierExamenCandidatAction,
	                                            intl,
	                                            classes,
	                                            snackError,
	                                            snackSuccess,
	                                            change,
	                                            refreshCandidatNoTelepiloteAction,
	                                            refreshBapdInfosAction
                                            }: PiecesJustificativesExamenCandidatProps) => {
	const [isPJLoading, setPJLoading] = React.useState(false)

	// Hack pour qu'on puisse rafraîchir les infos relatives au BAPD une seule fois sans rerender les PJs à l'infini...
	const [hasMajBapdInfos, setHasMajBapdInfos] = React.useState(false)
	React.useEffect(() => {
		if (!!examenSelectionne && !!examenSelectionne.id && !hasMajBapdInfos) {
			refreshBapdInfosAction(etatCivil.id, examenSelectionne.id)
			setHasMajBapdInfos(true)
		}
	}, [examenSelectionne])

	React.useEffect(() => {
		if (!!examenSelectionne && !!examenSelectionne.id) {
			setPJLoading(true);
			getPiecesJustificativesForExamenCandidatAction(etatCivil.id, examenSelectionne.id)
				.then(({ payload: piecesJustificatives }: any) => {
					// Insertion des PJ dans le Redux form
					change(FIELD_NAME_PJ, piecesJustificatives.map((pj: any) => ({
						...pj,
						validee: pj.validee
					})))
				})
				.catch(() => {
					change(FIELD_NAME_PJ, [])
				})
				.finally(() => {
					setPJLoading(false)
				})

				refreshCandidatNoTelepiloteAction(etatCivil.id)
		}

		return () => {
			resetPiecesJustificativesForExamenCandidatAction()
		}
	}, [examenSelectionne])

	// state popins
	// Popup déclaration de non-conformité
	const [openPopupDeclarationNonConforme, setOpenPopupDeclarationNonConforme] = React.useState(false)
	// Popup validation dossier
	const [openPopupValidationDossier, setOpenPopupValidationDossier] = React.useState(false)

	/**
	 * Coche ou décoche l'interrupteur de validation d'une PJ.
	 *
	 * @param event click event
	 * @param idPj  ID de la PJ
	 * @param field champ du formulaire correspondant
	 */
	const togglePjValidee = (event: ChangeEvent<any> | undefined, idPj: number, field: string) => {
		togglePjExamenCandidatValideeAction(etatCivil.id, examenSelectionne.id, idPj, event && event.target.checked)
			.catch(() => {
				snackError()
				change(`${field}.validee`, !(event && event.target.checked))
			})
	}

	/**
	 * Déclare le dossier de l'examen candidat comme étant non conforme.
	 *
	 * @param form formulaire de déclaration non conforme
	 */
	const declarerDossierNonConforme = (form: any) => {
		declarerDossierNonConformeExamenCandidatAction(etatCivil.id, examenSelectionne.id, form)
			.then(() => {
				setOpenPopupDeclarationNonConforme(false)
				snackSuccess(<FormattedMessage
					id="candidats.examens.piecesJustificatives.popins.declarerNonConforme.snackSuccess" />)
			})
			.catch(() => {
				snackError(<FormattedMessage id="global.erreurs.generic" />)
			})
	}

	/**
	 * Déclare le dossier de l'examen candidat comme étant validé.
	 */
	const validerDossier = () => {
		validerDossierExamenCandidatAction(etatCivil.id, examenSelectionne.id)
			.then(() => {
				setOpenPopupValidationDossier(false)
				snackSuccess(<FormattedMessage
					id="candidats.examens.piecesJustificatives.popins.validerDossier.snackSuccess" />)
			})
			.catch((e: any) => {
				snackError(<FormattedMessage id="global.erreurs.generic" />)
			})
	}

	const downloadPJ = (idPj: number, idFichier: number) => {
		checkFichierPJExamenCandidatExists(
			etatCivil.id,
			examenSelectionne.id,
			idPj,
			idFichier
		)
			.then((exists: boolean) => {
				if (exists) {
					download(
						`/files/examens/${examenSelectionne.id}/pieces-justificatives/${idPj}/${idFichier}`
					)
				} else {
					snackError(
						<FormattedMessage id="candidats.examens.piecesJustificatives.listePJ.fileNotExist" />
					)
				}
			})
			.catch(() => snackError(
				<FormattedMessage id="global.erreurs.generic" />
			))
	}

	const renderPj = ({ fields }: { fields: any }): any => <React.Fragment>
		{piecesJustificativesExamen.map((pj, index: number) => {
				const fieldLibelle = `${pj.libelle}${pj.obligatoire ? ' *' : ''}`;
				return (
					<Grid key={index} container direction="row" className={classes.row}>
						<Grid item md={10}>
							<Typography className={classes.pjType}>{fieldLibelle}</Typography>

							<div className={classes.pjBadgeList}>
								{
									pj.fichiers.map(fichier => (
										<PieceJustificativeNameBadge
											fileName={fichier.libelle}
											canDelete={false}
											handleClickBadge={() => downloadPJ(pj.id, fichier.id)}
										/>
									))
								}
							</div>
						</Grid>
						<Grid item md={2}>
							<Field
								name={`piecesJustificativesExamen[${index}].validee`}
								component={FormSwitch}
								color="primary"
								disabled={examenSelectionne.statutDossier !== EXAMEN_CANDIDAT_STATUT_DOSSIER.A_VALIDER}
								onChange={(event, value) => togglePjValidee(event, pj.id, `piecesJustificativesExamen[${index}]`)}
							/>
						</Grid>
					</Grid>
				)
			}
		)}
		{
			piecesJustificativesExamen.length === 0 && (
				<EmptyResult propClasses={classes.emptyPj}
				             message={<FormattedMessage id="candidats.examens.piecesJustificatives.listePJ.empty" />}
				/>
			)
		}
	</React.Fragment>

	return (
		<React.Fragment>
			{
				(
					examenSelectionne.messageStatutDossier
					&& examenSelectionne.statutDossier !== EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
				) && (
					<Error
						title={intl.formatMessage({ id: 'candidats.examens.piecesJustificatives.warningNonConforme.title' })}
						error={examenSelectionne.messageStatutDossier.split('\n')}
					/>
				)
			}

			<ResponsiveForm>
				<Title value={<FormattedMessage
					id="candidats.examens.piecesJustificatives.infosComplementaires.title" />} />

				<InputDisabled
					label={<FormattedMessage
						id="candidats.examens.piecesJustificatives.infosComplementaires.form.noTelepilote"
					/>}
				>
					{
						etatCivil.numeroTelepilote ?
							etatCivil.numeroTelepilote.replace('-RP-', ' - RP - ')
							: '-'
					}
				</InputDisabled>

				<InputDisabled
					label={<FormattedMessage
						id="candidats.examens.piecesJustificatives.infosComplementaires.form.titulaireBapd"
					/>}
				>
					<FormattedMessage
						id={examenSelectionne.certificatComplementaire ? 'global.btn.oui' : 'global.btn.non'}
					/>
				</InputDisabled>

				<Field id="dateExpCertifA1A3"
				       name="dateExpCertifA1A3"
				       label={<FormattedMessage
					       id={`candidats.examens.piecesJustificatives.infosComplementaires.form.${examenSelectionne.certificatComplementaire ? 'dateExpCertifBapd' : 'dateExpCertifA1A3'}`}
				       />}
				       component={DateInput}
				       disabled
				       type="date"
				/>

				<Title value={<FormattedMessage
					id={'candidats.examens.piecesJustificatives.listePJ.title'} />} />

				{
					(
						examenSelectionne.statutDossier !== undefined
						&& [
							EXAMEN_CANDIDAT_STATUT_DOSSIER.A_VALIDER,
							EXAMEN_CANDIDAT_STATUT_DOSSIER.NON_CONFORME,
							EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
						].includes(examenSelectionne.statutDossier)
					) && (
						<BandeauInfo message={
							<span dangerouslySetInnerHTML={{
								__html: intl.formatMessage(
									{ id: `candidats.examens.piecesJustificatives.listePJ.warning.${examenSelectionne.statutDossier}` },
									{
										dateStatutDossier: formatDate(
											examenSelectionne.dateStatutDossier!!,
											DATETIME_FORMAT_SERVER_TIMESTAMP,
											DATE_FORMAT_MMDDYYYY_SLASH
										),
										modificateurStatutDossier: examenSelectionne.modificateurStatutDossier
									}
								)
							}} />
						} />
					)
				}

				<Grid container direction="row">
					{
						piecesJustificativesExamen.length > 0 && (
							<React.Fragment>
								<Grid item md={10}>
									<Typography className={classes.header}>
										<FormattedMessage id="candidats.examens.piecesJustificatives.listePJ.headers.pj" />
									</Typography>
								</Grid>
								<Grid item md={2}>
									<Typography className={classes.header}>
										<FormattedMessage
											id="candidats.examens.piecesJustificatives.listePJ.headers.validee" />
									</Typography>
								</Grid>
							</React.Fragment>
						)
					}

					{
						isPJLoading ?
							<Loader />
							: (
								// @ts-ignore
								<FieldArray
									name={FIELD_NAME_PJ}
									component={renderPj}
									rerenderOnEveryChange
								/>
							)
					}

				</Grid>

				<Actions>
					{
						(examenSelectionne.statutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.A_VALIDER) && (
							piecesJustificativesExamen.findIndex(pj => !pj.validee) === -1 ?
								// Valider
								<Button
									color="primary"
									variant="contained"
									onClick={() => setOpenPopupValidationDossier(true)}
								>
									<FormattedMessage
										id="candidats.examens.piecesJustificatives.buttons.valider" />
								</Button>
								:
								// Déclarer non conforme
								<Button
									color="primary"
									variant="contained"
									onClick={() => setOpenPopupDeclarationNonConforme(true)}
								>
									<FormattedMessage
										id="candidats.examens.piecesJustificatives.buttons.declarerNonConforme" />
								</Button>
						)
					}
				</Actions>
			</ResponsiveForm>

			{openPopupDeclarationNonConforme && <PopupDeclarationNonConformite
				open={openPopupDeclarationNonConforme}
				closePopup={() => setOpenPopupDeclarationNonConforme(false)}
				actionValider={declarerDossierNonConforme}
				examenCandidat={examenSelectionne}
			/>}

			<PopupValidationDossierExamenCandidat
				open={openPopupValidationDossier}
				closePopup={() => setOpenPopupValidationDossier(false)}
				actionValider={validerDossier}
			/>
		</React.Fragment>
	)
}

const validate = (values: any): FormErrors<any> => {
	const errors: any = {}
	// RG6589/63626
	if (!values.numeroTelepiloteSuffixe || values.numeroTelepiloteSuffixe.length !== 12) {
		errors.numeroTelepiloteSuffixe = { id: 'inscriptions.piecesJustificativesDossier.infosComplementaires.form.erreurs.noTelepiloteSuffixeNbCaracteres' }
	}
	return errors
}

const mapStateToProps = (state: any, ownProps: PiecesJustificativesExamenCandidatProps) => {
	return ({
		initialValues: {
			dateExpCertifA1A3: (
				ownProps.examenSelectionne.dateExpirationJustificatif ?
					formatDate(ownProps.examenSelectionne.dateExpirationJustificatif, DATETIME_FORMAT_SERVER_TIMESTAMP, DATE_FORMAT_YYYYMMDD_DASH)
					: undefined
			)
		},
		piecesJustificativesExamen: getListePiecesJustificativesExamenSelectionne(state)
	})
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_EXAMENS_PRATIQUES,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectIntl,
	injectProfil,
	// @ts-ignore
	withStyles(styles),
	withTheme(),
	injectSnackActions,
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate,
		enableReinitialize: true
	})
)(PiecesJustificativesExamenCandidat)
